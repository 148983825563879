import React from "react";
import TtsItem from "./TtsItem";

function TtsList(props) {
  return (
    <div>
      {props.options.map((option) => (
        <TtsItem
          key={option.id}
          option={option}
          handleChangeProps={props.handleChangeProps}
          downloadAudioProps={props.downloadAudioProps}
        />
      ))}
    </div>
  );
}

export default TtsList;
