import React, { useState } from "react";

function TtsItem(props) {
  const [fileno, setFileno] = useState(1);
  const { id, input, output, status, message } = props.option;

  // Sample Object - Not Using
  const sampleData = {
    id: id,
    output: output,
    status: status,
    message: message
  };

  const handleDownload = async (e) => {
    try {
      const audioUrl = output;

      // Create a URL object from the audioUrl
      const url = new URL(audioUrl);
      // Get the pathname (file path) from the URL
      const pathname = url.pathname;
      // Extract the file name with extension from the pathname
      const fileNameWithExtension = pathname.split('/').pop();

      const fetchUrl = "https://api.metatechplus.com/v1/download?file="+fileNameWithExtension;

      const response = await fetch(fetchUrl);

      if (!response.ok) {
        throw new Error("Something went wrong. Please try again.");
      }
      console.log("Gmp3");
      const Gmp3 = URL.createObjectURL(await response.blob());
      console.log(Gmp3);
      
      if (Gmp3) {
        setFileno(fileno + 1);
        // Create an anchor element to trigger the download
        const a = document.createElement("a");
        a.href = Gmp3;
        a.download = "downloaded-audio-" + fileno + ".mp3"; // Specify the desired file name
        a.click();
      }
    } catch (error) {
      console.error("Error fetching MP3:", error);
    }
  };

  return (
    <li className="todo-item">
      <div className="todo-heading">
        <h3>{input}</h3>
      </div>
      <div className="assign-btn">
        <button onClick={handleDownload}>Download</button>
      </div>
    </li>
  );
}

export default TtsItem;
