import axios from 'axios';

const BASE_URL = 'https://api.metatechplus.com/v1';

// Function to fetch data from the API
export const generateData = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/generate`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Function to download data from the API
export const downloadAudio = async (data) => {
  try {
    // console.log(data.filename);
    const response = await axios.get(`${BASE_URL}/download?file=${data.filename}`, {
      responseType: 'blob', // Specify that the response should be treated as a binary blob
    });
    // console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add more API functions as needed
