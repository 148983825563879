import React from "react";

const Header = () => {
  const headerStyle = {
    paddingBottom: "20px",
    lineHeight: "2em",
  };
  return (
    <header style={headerStyle}>
      <h1
        style={{
          fontSize: "25px",
          marginBottom: "15px",
          textAlign: "center",
          color: "#724260",
        }}
      >
        Simple Text to Speech (TTS) Generator
      </h1>
      <h3
        style={{
          fontSize: "10px",
          marginBottom: "15px",
          textAlign: "left",
          color: "#79c562",
        }}>
          * Advanced - Coming soon
        </h3>
      <p style={{ fontSize: "19px" }}>
        Please add your text below
      </p>
    </header>
  );
};

export default Header;
