import React, { useState } from "react";
import {generateData} from "../services/ttsService";

// https://dev.to/aegli84/how-to-deploy-your-react-website-to-namecheap-web-hosting-provider-2ibn

function InputOptions(props) {

  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState("");

  const [buttonLabel, setButtonLabel] = useState('Submit');

  const handleSubmit = (e) => {

    e.preventDefault();

    setButtonLabel('Loading');

    const requestData = {
      input: input
    };

    // Sample Object - Not Using
    const sampleData = {
      input: input,
      output: output,
      status: status,
      message: message
    };

    // Use the generateData function to fetch data
    generateData(requestData)
    .then((result) => {
        const words = input.split(' ');
        // Get the first 3 words
        const title = words.slice(0, 3).join(' ');

        setInput(title);
        setOutput(result.output);
        setStatus(result.status);
        setMessage(result.message);

        props.addAudioProps(title, result.output, result.status, result.message);

        setInput("");
        setButtonLabel('Submit');

    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      {/* <input
        type="text"
        className="input-text"
        placeholder="Add todo..."
        value={input}
        name="title"
        onChange={handleChange}
      /> */}
      <textarea
        className="input-text"
        rows="4" // Specify the number of visible rows
        cols="50" // Specify the number of visible columns
        value={input} // Bind the textarea value to the 'text' state
        onChange={handleChange} // Handle changes in the textarea
        placeholder="Enter your text here..."
      ></textarea>
      <input
        type="submit"
        disabled={input ? false : true}
        className="input-submit"
        value={buttonLabel}
      />
    </form>
  );
}

export default InputOptions;
