import React, { useState } from "react";
import TtsList from "./TtsList";
import Header from "./Header";
import InputOptions from "./InputOptions";
import { v4 as uuidv4 } from "uuid";

function TtsContainer() {

  const audioOptions = [

  ];

  const [options, setOptions] = useState(audioOptions);

  const downloadAudio = (id) => {

  };

  const generateAudio = (input, output, status, message) => {
    const newAudio = {
      id: uuidv4(),
      input: input,
      output: output,
      status: status,
      message: message,
    };
    setOptions([...options, newAudio]);
  };

  return (
    <div className="container">
      <Header />
      <InputOptions addAudioProps={generateAudio} />
      <TtsList
        options={options}
        downloadAudioProps={downloadAudio}
      />
    </div>
  );
}

export default TtsContainer;
